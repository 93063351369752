.button {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    height: 44px;

    max-width: 225px;

    flex-shrink: 0;

    margin: 5px;
    padding-left: 20px;
    padding-right: 20px;

    font-size: 15.95px;
    font-family: 'Prompt', 'Prompt' sans-serif;

    &.button-special {
        background: var(--special-button-color);
        color: var(--text-alt-color);
    }

    &.button-roblox {
        background: var(--roblox-button-color);
        color: var(--text-alt-color);
    }

    &.button-discord {
        background: var(--discord-button-color);
        color: var(--text-color);
    }

    img {
        width: 18px;
        height: 18px;

        margin-right: 7px;
    }

    user-select: none;
    cursor: pointer;

    &:hover {
        filter: brightness(1.1);
    }

    transition: .2s linear filter;
}