.topbar {
    user-select: none;
    
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 80px;

    border-bottom: 2px solid var(--blur-color);
    backdrop-filter: blur(10px); 
    background-color: var(--blur-color);

    z-index: 15;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-family: "Prompt", "Prompt", sans-serif;

    .topbar-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 20px;

        margin-right: 50px;

        

        .active {
            margin-top: 2px;
            border-bottom: 2px solid var(--text-color);
        }

        a {
            cursor: pointer;
        }

        @media (max-width: 700px) {
            display: none;
        }
    }

    .topbar-logo {
        margin-left: 35px;

        @media (max-width: 700px) {
            margin-left: 0px;
        }

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 20px;
            margin-left: 15px;
            font-family: "Prompt", "Prompt", sans-serif;
        }

        img {
            height: 40px;
        }
    }

    

    @media (min-width: 700px) {
        justify-content: space-between;
    }

    @media (max-width: 700px) {
        justify-content: center;
    }
}