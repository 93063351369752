.experiences {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;

    padding: 100px;

    border-bottom: 2px solid var(--background-color-lighter);

    flex-shrink: 0;
    
    @media (max-width: 1200px) {
        flex-direction: column;

        gap: 50px;
    }

    .experiences-title {
        width: 356px;
        margin-left: 100px;
        margin-right: 25px;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        text-align: left;

        @media (max-width: 1200px) {
            text-align: center;
            margin: 0px;
        }

        white-space: break-spaces;
    }

    .experiences-list {
        width: 75%;

        margin-left: 25px;
        margin-right: 100px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        gap: 15px;

        @media (max-width: 1200px) {
            margin-left: 0px;
            margin-right: 0px;
        }

        .experience {
            background-color: var(--background-color-lighter);

            position: relative;

            width: 100%;
            height: 288px;

            display: flex;
            flex-direction: row;

            @media (max-width: 800px) {
                flex-direction: column;
                align-items: center;

                height: 388px;
            }

            border-radius: 7px;
        }
        
        .experience-image {
            width: 100%;
            max-width: 510px;
            
            height: 100%;

            @media (max-width: 800px) {
                height: 200px;
                max-width: initial;
            }

            img {
                border-radius: 7px;

                width: 100%;
                height: 100%;

                object-fit: cover;
                user-select: none;
            }
        }

        .experience-info {
            position: relative;

            text-align: left;
            white-space: pre-line; 

            height: 100%;
            width: 100%;

            margin-left: 15px;

            @media (max-width: 800px) {
                width: 98%;
                margin-left: 0px;
            }

            h3 {
                margin-top: 15px;
                margin-bottom: 10px;

                font-family: "Lexend Deca Black", "Lexend Deca", sans-serif;
                font-size: 20px;

                width: 100%;

                text-overflow: ellipsis;
                white-space: nowrap;
            }

            p {
                font-size: 12px;
                line-height: 200%;
                word-wrap: break-word;
            
                max-height: 60%;

                @media (max-width: 800px) {
                    max-height: 85px;
                }

                -webkit-mask-image: linear-gradient(180deg, #000 10%, transparent);
            }

            .play-info {
                position: absolute;
                bottom: 15px;
                left: 0px;

                width: 100%;

                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;

                .button {
                    margin-right: 15px;
                }
            }
        }
    }
}